.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-number {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

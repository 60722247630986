.FlippinRev {
  position: relative;
  min-height: 110px;
}
.fliper-container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  -webkit-perspective: 300px;
  perspective: 300px;
}
.fliper-coin {
  height: 150px;
  width: 150px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.fliper-coin img {
  width: 145px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
}
.fliper-heads,
.fliper-tails {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.fliper-tails {
  transform: rotateX(180deg);
}
@keyframes spin-tails {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(1980deg);
  }
}
@keyframes spin-heads {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(2160deg);
  }
}

.Flip-win {
  margin: 5px;
  color: red;
  text-decoration: uppercase;
  text-align: center;
}

.withdraw-bitcoin-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.select-items-div {
  width: 100%;
  /* height: 400px; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px; */

  border-radius: 12px;
}
.withdraw-bitcoin-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.withdraw-next {
  border: none;
  font-size: 15px;
  font-weight: 500;
  background-color: #e30613;
  border-radius: 30px;
  color: white;
  padding: 10px;
}
.withdraw-edit {
  border: none;
  font-size: 15px;
  font-weight: 500;
  background-color: #2570b5;
  border-radius: 30px;
  color: white;
  padding: 8px;
  width: 30%;
}

.btc-img {
  height: 30px;
  width: 30px;
}
/* //withdraw cards */
.card-main-withdraww {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}
.card-img-withdraw {
  height: 60px;
  width: 60px;
}
.withdraw-card-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.withdraw-card-head {
  background-color: #f0f0f2;
}
.withdraw-card-title {
  margin: 0 auto !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.withdraw-card-text {
  font-size: 10px !important;
  font-weight: 500 !important;
}
.selectall-txt {
  cursor: pointer;
  color: #e30613;
}
.WithdrawCard {
  padding: 4px;
  border: none;
  cursor: pointer;
}
.withdraw-input {
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  height: 52px;
  width: 100%;
  outline: none;
}
.withdraw-bitcoin-footer-div {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.8;
}

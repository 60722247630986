.AppLight {
  background-color: white;
  color: black;
  font-style: normal;
}

.AppDark {
  background-color: black;
  color: white;
  font-style: normal;
}

/* .blur {
  filter: blur(3px);
  pointer-events: none;
} */
.modal-backdrop {
  opacity: 0.8 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.error {
  color: red;
  font-size: small;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-app-loader {
  height: 100vh;
  width: 100%;
  margin-left: -50px;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.full-app-loader-dark {
  background-color: black;
}
.full-app-loader-light {
  background-color: white;
}
.full-app-loader img {
  margin-bottom: 20px;
}

.pointerSpinner {
  position: absolute;
  top: 0;
  left: 50%;
  width: 3px;
  height: 100px;
  background-color: red;
}
button:hover {
  box-shadow: 0px 3px 6px rgba(227, 6, 19, 0.5);
  transform: translateY(-1px);
}
a:hover {
  color: black !important;
  transition: color 0.3s ease !important;
}

.custom-card {
  flex: 0 0 297px;
  height: 270px !important;
  max-width: 210px;
  border: 1px solid #f1f5f9;
  overflow-y: hidden;
  margin-left: 15px;
  border-radius: 12px;
  cursor: pointer;
}
@media (max-width: 1204) {
  .custom-card {
    padding: unset !important;
  }
}
.custom-card-dark {
  background: #171717 !important;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.4),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.custom-card-light {
  background: #ffffff;
  border: 1px solid #f1f5f9;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.scroldiv::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.scroldiv {
  overflow-x: auto;
}

.featured-box {
  border-radius: 0px;
}

.cardimg {
  padding: 32px 32px 0px 32px;
}

.cardtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
}
.cardtext-dark {
  color: #ffffff;
}
.cardtext-light {
  color: #000000;
}
.cardtext {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.4;
  text-align: center;
  letter-spacing: -0.02em;
}

.badges {
  padding: 13px;
}
.featured-button {
  margin-left: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  border: none;
  border-radius: 30px;
  color: #ffffff;
  background: #e30613;
  height: 20.5px;
  padding: 2px 8px 2.5px;
  text-transform: uppercase;
}
.newbutton {
  border: none;
  padding: 2px 8px 2.5px;
  width: 40px;
  height: 20.5px;
  background: #e30613;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #ffffff;
}

.reveal {
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 50px;
  height: 3%;
  border-radius: 50% / 100% 100% 0 0;
  transition: height 0.3s;
}
.item-price {
  margin-top: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.custom-card-dark .card-title {
  height: 35px;
  font-size: 15px;
  transform: translateY(-23px);
}

.custom-card-light .card-title {
  font-size: 16px;
  transform: translateY(-23px);

  font-weight: 600;
}

.card .reveal {
  height: 45px;
  left: 0;
  width: 100%;
}
.card-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .card-img-div img {
  width: 180px;
  height: 170px;
} */
/* .custom-card::-webkit-scrollbar {
  background-color: transparent;
} */
.custom-card:hover .cardimg {
  transform: rotate(25deg) translateY(-2px);
  transition: transform 0.3s ease;
}
.custom-card:hover {
  overflow: hidden;
}

.container-countdown {
  filter: contrast(20);
  background-color: white;
  overflow: hidden;
}
.container-countdown-dark {
  background-color: black;
}
.spanText {
  color: black;
  font-size: 8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  filter: blur(0.2rem);
  display: block;
  transition: all 1.5s ease;
  opacity: 0;
  filter: blur(0.6rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:nth-child(1) {
    animation: letteranim 11s infinite ease;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation: letteranim 11s infinite ease;
    animation-delay: 1s;
  }
  &:nth-child(3) {
    animation: letteranim 11s infinite ease;
    animation-delay: 2s;
  }
  &:nth-child(4) {
    animation: letteranim 11s infinite ease;
    animation-delay: 3s;
  }
}
.spanText-dark {
  color: white;
}
@keyframes letteranim {
  0% {
    opacity: 0;
    filter: blur(0.6rem);
  }
  5% {
    opacity: 1;
    filter: blur(0.2rem);
  }
  10% {
    opacity: 1;
    filter: blur(0.2rem);
  }
  20% {
    opacity: 0;
    filter: blur(0.6rem);
  }
  100% {
    opacity: 0;
    filter: blur(0.6rem);
  }
}

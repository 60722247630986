.main-battle-detail {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 200px);
  background-image: radial-gradient(circle, #ffffff 0%, #ffdcdc 100%);
}
.main-battle-detail-dark {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 200px);
  background-image: radial-gradient(circle, #ffffff 0%, #181f20 100%);
}

.battle-header {
  padding: 20px;
  background-color: #f9f9f9;
}
.battle-header-dark {
  padding: 20px;
  background-color: #6b6969;
}
.battle-back-btn {
  width: 100px;
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #e30613;
  color: white;
}
.battle-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Battle-Footer-Section1-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.user-icon img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.battle-footer {
  /* position: relative; */
  margin-top: 2px !important;
  outline: 2px solid #e30613;
  outline-offset: -2px;
  padding: 20px;
}
.battlefooter-looser {
  padding: 20px;
}
/* .battle-footer-2nd {
  position: relative;
  margin-top: 2px !important;
  outline: 2px solid #e30613;
  outline-offset: -2px;
  padding: 10px;
} */
.Battle-Footer-Section1-des h6 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.85rem;
}
.Battle-Footer-Section1-des span {
  background-color: #f0f0f2;
  padding: 3px;
  color: #000;
}
.battle-footer-cards-main {
  display: flex;

  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;
}
.battle-footer-card {
  padding: 10px;
  width: 150px;
  text-align: center;
}
.battles-footer-cards-div {
  display: flex;
  flex-wrap: wrap;
}
.battle-footer-card-dark {
  background: #171717 !important;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.4),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.battle-footer-card-light {
  background: #ffffff;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.battle-footer-card img {
  width: 100px;
  height: 100px;
}
@media (max-width: 1024px) {
  .battle-footer-card img {
    width: 90px;
    height: 90px;
  }
}
@media (max-width: 900px) {
  .battle-footer-card img {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 670px) {
  .battle-footer-card img {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 425px) {
  .battle-footer-card img {
    width: 60px;
    height: 60px;
  }
  .winner-btn {
    width: 14% !important;
  }
}

.battle-card-item-price {
  background-color: #f0f0f2;
  margin-right: 4px;
  padding: 2px;
  color: #000;
}

.footer-card-end {
  padding-top: 10px;
}
.icon-between-cols {
  position: absolute;
}
.icon-between-cols img {
  position: absolute;
  top: 5px;
  right: 48%;
}
@media (max-width: 767px) {
  .icon-between-cols img {
    display: none;
  }
}
.winner-btn {
  position: absolute;
  top: 4px;
  height: 1.5rem;
  font-size: 0.635rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #e30613;
  display: flex;
  align-items: center;
  width: 10% !important;
  justify-content: center;
}

.end-battle-detail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle, #ffffff 0%, #ffdcdc 100%);
  padding: 10px;
  overflow: hidden;
  height: calc(100vh - 200px);
}
.end-battle-detail-dark {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle, #ffffff 0%, #181f20 100%);
  padding: 10px;
  overflow: hidden;
  height: 70%;
}
.battle-dummy-card {
  min-width: 150px;
  min-height: 150px;
  background-color: #d1c9c9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.battle-end-icons {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}
.battle-end-icons-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.battle-end-icons-details span {
  font-size: 0.9rem;
  font-weight: 700;
}
.battle-end-icons-details img {
  max-width: 100px;
  border-radius: 12px;
}
.backtoBattlebtn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  color: #ffffff;
  background: #e30613;
  text-transform: uppercase;
  /* padding: 8px; */
}
.backtoBattlebtn span {
  padding: 5px;
}

.sameBattlebtn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  color: #ffffff;
  background: #e30613;
  text-transform: uppercase;
  padding: 8px;
}
@media (max-width: 425px) {
  .sameBattlebtn {
    font-size: 10px;
  }
  .backtoBattlebtn {
    font-size: 10px;
  }
}
.sameBattlebtn span {
  padding: 5px;
}
.sameBattlebtn img {
  height: 20px;
  width: 20px;
}
.endBattleBtns {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dummy-product-container {
  width: 150px;
  height: 200px;
  background-color: #d3d3d3;
}

/* //battle wait */
.cancel-text {
  color: #e30613;
  cursor: pointer;
}
.battlewait-link {
  text-decoration: none;
  color: black;
  /* font-size: 12px; */
}
.open-text {
  color: black !important;
}
.battle-wait-head-dark {
  padding: 20px;
}

.playwithbot {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #e30613;
  color: white;
}
.rounds-light {
  padding: 30px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rounds-dark {
  padding: 30px;
  background-color: #181f20;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes-in-battle-wait {
  overflow-y: scroll;
}
.boxes-in-battle-wait::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.boxes-in-battle-wait {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.provablyfair-text {
  text-decoration: none;
  color: #e30613;
}
.provablyfair-text:hover {
  color: #e30613;
}

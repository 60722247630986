.customClass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.headerlinks {
  padding: 10px;
}
.headerDark {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: #fff !important;
}
.selected-link-dark {
  background: rgba(255, 128, 135, 0.15);
  color: rgba(255, 77, 87, 1) !important;
  border-radius: 4px;
}
.selected-link-light {
  background: rgba(227, 6, 19, 0.05);
  color: rgba(227, 6, 19, 1) !important;
  border-radius: 4px;
}
.headerLight {
  background: rgba(248, 250, 252, 1);
  border-radius: 4px;
}

.headerOptionsLight span {
  padding: 10px;
  padding-bottom: 5px;
  font-weight: 600;
}
.headerOptionsDark span {
  padding: 10px;
  padding-bottom: 5px;
  font-weight: 600;
}
.SignUpbutton {
  /* margin-left: 20px; */
  background-color: #e30613;
  border: none;
  box-shadow: 0px 12px 8px -12px rgba(227, 6, 19, 0.4);
  border-radius: 30px;
  /* padding: 8px 16px 8px 12px; */
  color: white !important;
  width: 91px;
  height: 32px;
}
.loginbutton {
  color: #e30613;
  text-decoration: none;
  /* padding: 8px 16px; */
  border: none;

  width: 65px;
  height: 32px;

  background: rgba(237, 74, 83, 0.25);
  border-radius: 50px;
}
.SignUpbutton img {
  margin-right: 5px;
  color: white;
}

.buttonSide {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.toggle-buttons {
  flex-grow: 1;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.toggleIcon {
  margin-right: 5px;
}
@media screen and (max-width: 991px) {
  .toggleIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .SignUpbutton {
    margin: 0px;
    /* margin-left: 20px; */
  }
  .buttonSide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .themeText {
    color: white;
  }
}
@media screen and (max-width: 500px) {
  .buttonSide {
    flex-grow: 1;
    flex-wrap: wrap;
  }
}

.navCart {
  font-size: 22px;
  cursor: pointer;
}
.navCartDark {
  color: white;
}
.account-balance-div {
  display: flex;
  margin-right: 10px;
  color: black;
  background-color: #f6f6f8;
}
.account-balance {
  display: flex;
  align-items: center;
  padding: 0 0.25rem 0 0.5rem;
  font-weight: 600;
}
.account-balance span {
  width: 100%;
}

.plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #e30613 !important;
  color: #fff !important;
  height: 25px;
}
.navbar-nav {
  gap: 4px !important;
}
.headerCol a {
  padding: 5px;
}

.buttonOnToggle {
  width: 100%;
}

.loader-payment-gateway {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

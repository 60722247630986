.stats-info {
  display: flex;
  flex-direction: column;
}
.stats-main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.stats-card-light {
  background: #ffffff;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 12px;
}
.stats-card-dark {
  background: #171717;
  box-shadow:
    0px 40px 40px -40px rgba(200, 118, 251, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 12px;
}

.delivery-icon {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: none;
}
.delivery-icon-light {
  background: #ffffff;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.delivery-icon-dark {
  background: #000000;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.slick-slide img {
  display: inline !important;
}
.slick-slide {
  height: 100% !important;
}
.div-main {
  width: 96%;
  /* max-width: 350px; */
}

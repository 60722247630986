.heading-h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6875rem;
  padding-bottom: 1.5rem;
}

.vertical-line {
  border-top: 1px solid black;
  transform: rotate(90deg);
}

.formgroup {
  margin-bottom: 20px;
}
.heading-h5 {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}

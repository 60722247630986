.quick-table {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.quick-table-dark {
  background: #171717;
  box-shadow:
    -8px 40px 40px -40px rgba(200, 118, 251, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.quick-table-light {
  background: #ffffff;
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.quick-row {
  display: flex;
  flex-basis: 100%;
  margin-top: 10px !important;
  flex-wrap: wrap;
}

.quick-cell {
  flex-basis: 12.5%; /* 100% / 8 cells = 12.5% */
  padding: 10px;
  border: none;
  font-size: 10px;
  font-weight: 500;
  color: #8e8e99;
  text-transform: uppercase;
}

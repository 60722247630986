.formInput {
  // min-width: 350px;
  // margin-top: 19px;
  position: relative;
  // min-height: 41px;
  label {
    // font-family: $Poppins;
    font-style: normal;

    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    /* identical to box height */
    display: block;

    letter-spacing: 0.025em;

    color: black;
    margin-bottom: 4px;
  }
  input {
    width: 352px;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 10px;

    height: 52px;
    @media screen and (max-width: 550px) {
      width: 310px;
    }
    @media screen and (max-width: 370px) {
      width: 252px;
    }
    position: relative;
    min-height: 41px;
    // font-family: $Poppins;
    font-style: normal;

    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    padding: 0px 16px;
    letter-spacing: 0.025em;
    // margin-bottom: 10px;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // color: $grey-color;
    &:focus-visible {
      outline: darkgray auto 1px;
      background-color: #e8f0fe;
    }
    &::placeholder {
      // font-family: $Poppins;
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      // color: $text_color;
    }
  }
  .NEWloginElement input {
    padding-left: 40px !important;
  }
  textarea {
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    width: 100%;
    min-height: 41px;
    font-family: "poppins-light";
    font-style: normal;
    height: 200px;
    display: block;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    resize: none;
    padding: 10px 16px !important;
    letter-spacing: 0.025em;

    // color: $grey-color;
    &:focus-visible {
      outline: darkgray auto 1px;
    }
    &::placeholder {
      // font-family: $Poppins;
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      // color: $text_color;
    }
  }
  .showPass {
    position: absolute;
    right: 0;
    top: 15px;
    padding-right: 10px;
    @media screen and (max-width: 550px) {
      left: 200px;
      top: 15px;
    }
    cursor: pointer;
    svg {
      font-size: 20px;
      color: darkgray;
    }
  }
}

.formInputDark {
  // min-width: 350px;
  // margin-top: 19px;
  position: relative;
  // min-height: 41px;
  label {
    // font-family: $Poppins;
    font-style: normal;

    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    display: block;

    letter-spacing: 0.025em;

    color: white;
    margin-bottom: 4px;
  }
  input {
    width: 352px;
    background: #1e293b;
    border: 1px solid #334155;
    border-radius: 10px;
    color: #e2e8f0;
    height: 52px;
    @media screen and (max-width: 550px) {
      width: 230px;
    }
    position: relative;
    min-height: 41px;
    // font-family: $Poppins;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    padding: 0px 16px;
    letter-spacing: 0.025em;
    // margin-bottom: 10px;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // color: $grey-color;
    &:focus-visible {
      outline: none;
      background-color: #1e293b;
      border: none;
      color: white;
    }
    &::placeholder {
      // font-family: $Poppins;
      font-style: normal;
      color: white;
      opacity: 0.5;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      // color: $text_color;
    }
  }
  textarea {
    background: #1e293b;
    border: 1px solid #334155;
    border-radius: 6px;
    width: 100%;
    min-height: 41px;
    font-family: "poppins-light";
    font-style: normal;
    height: 200px;
    display: block;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    resize: none;
    padding: 10px 16px !important;
    letter-spacing: 0.025em;

    // color: $grey-color;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      // font-family: $Poppins;
      font-style: normal;

      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: 0.025em;

      // color: $text_color;
    }
  }
  .showPass {
    position: absolute;
    right: 0;
    top: 15px;
    padding-right: 10px;
    @media screen and (max-width: 550px) {
      left: 200px;
      top: 15px;
    }
    cursor: pointer;
    svg {
      font-size: 20px;
      color: darkgray;
    }
  }
}

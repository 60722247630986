.myaccount-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 20px;
}
.list-group-item-active {
  background-color: rgb(107, 106, 106) !important;
}
.myaccount-menu-btn {
  border: none;
  border-radius: 30px;
  padding: 8px;
  background-color: #e30613;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.myaccount-menu-btn span {
  padding: 6px;
  text-transform: uppercase;
}

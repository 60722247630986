.listitems {
  /* background-color: black;
    color: #fff; */

  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
}
.logout-button {
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #e30613;
  cursor: pointer;
  font-weight: 500;
  color: white;
  box-shadow: 0px 12px 8px -12px rgba(227, 6, 19, 0.4);
  border-radius: 30px;
  width: 95px;
  height: 35px;
  margin-bottom: 20px;
}
.logout-button span {
  font-size: 14px;
  font-weight: 600;
}
.logout-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fresh-div {
  display: flex;
  justify-content: flex-start;
}
.fresh-img {
  width: 36px;
  height: 40px;
  margin-left: 15px;
  margin-right: 16px;
  margin-top: 3px;
}
.fresh-description-div {
  display: flex;
  margin-left: 30px;
  justify-content: space-between;
}
@media screen and (max-width: 760px) {
  .fresh-img {
    width: 28px;
    height: 32px;
  }
}
@media screen and (max-width: 560px) {
  .fresh-img {
    width: 22px;
    height: 24px;
  }
}
@media screen and (max-width: 400px) {
  .fresh-img {
    width: 20px;
    height: 22px;
  }
  .fresh-description-div {
    flex-wrap: wrap;
  }
  .fresh-description {
    font-size: 12px !important;
  }
}

.fresh-text {
  text-align: start;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
}
@media screen and (max-width: 1024px) {
  .fresh-text {
    font-size: 40px;
    font-weight: 600;
  }
}
@media screen and (max-width: 991px) {
  .fresh-text {
    font-size: 30px;
    font-weight: 600;
  }
}
@media screen and (max-width: 560px) {
  .fresh-text {
    width: 250px;
    height: 20px;
    font-size: 22px;
    font-weight: 500;
  }
}
@media screen and (max-width: 460px) {
  .fresh-text {
    width: 170px;
    height: 18px;
    font-size: 20px;
    font-weight: 500;
  }
}
.fresh-description {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  margin-top: 5px;
  opacity: 0.8;
}
@media screen and (max-width: 760px) {
  .feature-description {
    width: 400px;
    height: 20px;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 60px;
  }
}
@media screen and (max-width: 560px) {
  .feature-description {
    width: 250px;
    height: 15px;
    font-size: 10px;
    margin-top: 3px;
    margin-left: 52px;
  }
}
@media screen and (max-width: 560px) {
  .feature-description {
    width: 196px;
    height: 15px;
    font-size: 9px;
    margin-top: 3px;
  }
}
.discription-col {
  /* margin-top: 5px; */
  display: flex;
}
.see-more-div {
  margin-right: 15px;
}
@media screen and (max-width: 760px) {
  .see-more-div {
    margin-right: 7px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-div {
    margin-right: 5px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-div {
    margin-right: 3px;
  }
}

.see-more {
  width: 71px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: #e30613;
  margin-right: 3px;
}
@media screen and (max-width: 760px) {
  .see-more {
    font-size: 13px;
    width: 60px;
    height: 20px;
  }
}
@media screen and (max-width: 560px) {
  .see-more {
    font-size: 12px;
    width: 50px;
    height: 15px;
  }
}
@media screen and (max-width: 560px) {
  .see-more {
    font-size: 9px;
    width: 30px;
    height: 13px;
  }
}
.see-more:hover {
  color: #e30613;
}

.see-more-arrow {
  width: 13.75px;
  height: 13.75px;
}
@media screen and (max-width: 760px) {
  .see-more-arrow {
    width: 10px;
    height: 11px;
  }
}
@media screen and (max-width: 560px) {
  .see-more-arrow {
    width: 8px;
    height: 9px;
  }
}
@media screen and (max-width: 400px) {
  .see-more-arrow {
    width: 8px;
    height: 9px;
  }
}
.insta-twitter-link {
  color: #e30613;
  text-decoration: none;
}
.insta-twitter-link:hover {
  color: #e30613;
}
/* .mainfreshdiv {
  background-image: url(../../assets/images/freshdeliveries/freshback.png);
  background-repeat: no-repeat;
  background-position: 0ch;
  background-size: auto;
} */

Form.Control option {
  border: 1px solid black; /* add a border */
  height: 30px; /* set the height */
  width: 200px; /* set the width */
  background-color: white; /* set the background color */
}

Form.Control option:hover {
  background-color: gray; /* change the background color on hover */
}
.documents-form-grp {
  margin-top: 4rem;
  text-align: center;
  margin-bottom: 2rem;
  padding: 50px;
}
.documents-form-grp-dark {
  border: 1px dashed white;
}
.documents-form-grp-light {
  border: 1px dashed black;
}

.styles_faq-row-wrapper__3vA1D {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 10px;
  font-weight: bolder;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  padding: 0 10px;
}
/* .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border: 1px solid #ccc !important;
} */
.styles_faq-row-wrapper__3vA1D {
  box-shadow:
    0px 40px 40px -40px rgba(255, 92, 101, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  font-size: 13px !important;
}
